import React, { useState, useEffect } from "react";

const ProfileImageUploader = ({
  componetProperties,
  oComponentModel,
  aUIComponents,
}) => {
  // Use state to store the image preview URL
  const [previewUrl, setPreviewUrl] = useState("");
  const [imageName, setimageName] = useState("");

  useEffect(() => {
    if (oComponentModel.Files && oComponentModel.previews) {
      setPreviewUrl(oComponentModel.previews);
      setimageName(oComponentModel.binary[0].filename);
    }
  }, [oComponentModel.Files]);

  const handleFileChange = async (event) => {
    const selectedFiles = event.target.files;
    const filesArray = Array.from(selectedFiles);

    const maxFileSize = parseInt(componetProperties.imageSize) * 1024 * 1024;

    const largeFiles = filesArray.filter((file) => file.size > maxFileSize);

    if (largeFiles.length > 0) {
      alert(`File exceed the maximum size. Please upload smaller files.`);
      return;
    }

    if (filesArray.length > 0) {
      oComponentModel["Files"] = filesArray;
      const processedData = await processFiles(oComponentModel);
      setimageName(processedData[0].filename);
      oComponentModel.binary = processedData;
    }

    if (selectedFiles.length > 0) {
      // Create preview URLs and store in state
      const previewUrls = filesArray.map((file) => URL.createObjectURL(file));
      oComponentModel.previews = previewUrls[0];
      setPreviewUrl(previewUrls[0]);
    }
  };

  async function processFiles(oComponent) {
    const filesWithData = [];
    for (let i = 0; i < oComponent.Files.length; i++) {
      let file = oComponent.Files[i];
      let oBinaryFile = {};
      try {
        const arrayBuffer = await file.arrayBuffer();
        const base64String = arrayBufferToBase64(arrayBuffer);
        oBinaryFile.filename = file.name;
        oBinaryFile.content = base64String;
        oBinaryFile.filetype = file.type;
        filesWithData.push(oBinaryFile);
      } catch (error) {
        console.error("Error reading file:", error);
      }
    }

    return filesWithData;
  }

  function arrayBufferToBase64(buffer) {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  // Render the component
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={previewUrl || ""}
        alt={componetProperties.imageName || "Uploaded Image"}
        style={{ maxWidth: "100%", maxHeight: "90%", objectFit: "contain" }}
      />
      {previewUrl && (
        <a href={previewUrl} download={imageName}>
          {imageName}
        </a>
      )}
      {!componetProperties.bReadOnly && (
        <input type="file" accept="image/*" onChange={handleFileChange} />
      )}
    </div>
  );
};

export default ProfileImageUploader;
