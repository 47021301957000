import Papa from "papaparse";

const handleDownload = (filename, data) => {
  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.setAttribute("download", `${filename}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const DownloadCSV = (aProperties, oParams, oLogicDatas) => {
  var sDataName = aProperties.find(
    (oProperty) => oProperty.id === "idMapJsontoCsv"
  ).value.tableValue;
  var sFileName = aProperties.find(
    (oProperty) => oProperty.id === "idCSVDownloadFileName"
  ).value;

  if (oParams) {
    if (oParams.TableData) {
    } else if (Array.isArray(oParams)) {
      var data = oParams.find(
        (oParamsdata) => oParamsdata.field === sDataName
      ).value;
      if (data && data.length > 0) {
        handleDownload(sFileName, data);
      } else {
        let matchingUIData = oLogicDatas.aCurrentPage.find(function (oUIData) {
          if (oUIData.Component === "Table") {
            return oUIData.properties.some(function (oProperty) {
              return (
                oProperty.id === "idInputTableName" &&
                oProperty.value === sDataName
              );
            });
          }
          return false;
        });
        if (matchingUIData) {
          let data;
          matchingUIData.properties.forEach(function (oData) {
            if (oData.id === "idColumnConfig") {
              data = [
                oData.value.reduce((acc, curr) => {
                  acc[curr.fieldName] = "";
                  return acc;
                }, {}),
              ];
              handleDownload(sFileName,data)
            }
          });
        }
      }
    } else {
      handleDownload(sFileName, []);
    }
  } else {
    handleDownload(sFileName, []);
  }
};
