import React, { useEffect, useRef, useState } from "react";
import "@arcgis/core/assets/esri/themes/light/main.css";
import MapView from "@arcgis/core/views/MapView";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Graphic from "@arcgis/core/Graphic";
import Point from "@arcgis/core/geometry/Point";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import WebStyleSymbol from "@arcgis/core/symbols/WebStyleSymbol";
import Extent from "@arcgis/core/geometry/Extent";
import Map from "@arcgis/core/Map";
import TextSymbol from "@arcgis/core/symbols/TextSymbol";
import { webMercatorToGeographic } from "@arcgis/core/geometry/support/webMercatorUtils";
import DistanceMeasurement2D from "@arcgis/core/widgets/DistanceMeasurement2D.js";
import Search from "@arcgis/core/widgets/Search.js";
import Slider from "@arcgis/core/widgets/Slider.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/free-regular-svg-icons";
import { faStop } from "@fortawesome/free-solid-svg-icons";
import "./esri.css";

const MapViewComponent = ({
  graphicsData,
  bLocationTrack,
  oComponentModel,
  positionChange,
}) => {
  const mapViewRef = useRef(null);
  const graphicsLayerRef = useRef(null);
  const userLayerRef = useRef(null);
  const [userPosition, setUserPosition] = useState(null);
  const [initialViewSet, setInitialViewSet] = useState(false);
  const [bRender, setrender] = useState(false);
  const [bKmtracking, setKmtracking] = useState(false);
  const kmtrackingRef = useRef(bKmtracking);
  const [isSimulating, setIsSimulating] = useState(false);
  const [isDrag, setIsDrag] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [slider, setSlider] = useState(null);
  const simulationTimeoutRef = useRef(null);
  const [animationFrameId, setAnimationFrameId] = useState(null);

  const markPostiton = oComponentModel.properties.find(
    (data) => data.id === "idMarkLocation"
  )?.value;

  const livewithStops = oComponentModel.properties.find(
    (data) => data.id === "idLiveLocationStop"
  )?.value;

  const bSimulation = oComponentModel.properties.find(
    (data) => data.id === "idSimulation"
  )?.value;

  useEffect(() => {
    kmtrackingRef.current = bKmtracking;
  }, [bKmtracking]);

  function setActiveWidget(type, measurementWidget) {
    switch (type) {
      case "distance":
        measurementWidget.visible = true;
        measurementWidget.viewModel.start();
        var actionbutton = document.getElementById("distanceButton");
        if (actionbutton) {
          actionbutton.classList.add("active");
        }
        break;
      case null:
        measurementWidget.visible = false;
        measurementWidget.viewModel.clear();
        var actionbutton = document.getElementById("distanceButton");
        if (actionbutton) {
          actionbutton.classList.remove("active");
        }
        break;
    }
  }

  function formatTime(isoString) {
    const date = new Date(isoString);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds} ${period}`;
  }

  useEffect(() => {
    const map = new Map({
      basemap: "streets-navigation-vector", // Use "streets" basemap
    });
    mapViewRef.current = new MapView({
      container: "mapViewDiv",
      map: map,
    });

    mapViewRef.current.when(() => {
      graphicsLayerRef.current = new GraphicsLayer();
      userLayerRef.current = new GraphicsLayer();
      map.add(graphicsLayerRef.current);
      map.add(userLayerRef.current);
      if (markPostiton) {
        let measurementWidget = new DistanceMeasurement2D({
          view: mapViewRef.current,
          visible: false,
        });
        mapViewRef.current.ui.add(measurementWidget, "top-right");
        const topbar = document.getElementById("topbar");
        if (topbar) {
          topbar.style.display = "flex";
        }

        const searchWidget = new Search({
          view: mapViewRef.current,
        });
        mapViewRef.current.ui.add(searchWidget, {
          position: "top-right",
        });

        document
          .getElementById("distanceButton")
          .addEventListener("click", function () {
            setActiveWidget(null, measurementWidget);
            if (!kmtrackingRef.current) {
              setKmtracking(true);
              setActiveWidget("distance", measurementWidget);
            } else {
              setKmtracking(false);
              setActiveWidget(null, measurementWidget);
            }
          });

        document
          .getElementById("mapViewDiv")
          .addEventListener("dblclick", function () {
            measurementWidget.viewModel.clear();
            measurementWidget.visible = false;
            var actionbutton = document.getElementById("distanceButton");
            if (actionbutton) {
              actionbutton.classList.remove("active");
            }
          });

        mapViewRef.current.on("double-click", function (event) {
          event.stopPropagation();
          const { latitude, longitude } = webMercatorToGeographic(
            event.mapPoint
          );
          positionChange(oComponentModel, { latitude, longitude });
        });
        mapViewRef.current.on("double-click", ["drag"], function (event) {
          event.stopPropagation();
        });
      }
      setrender(true);
    });
  }, []);

  //useEffect for track location
  useEffect(() => {
    // Watch user position
    if (bLocationTrack) {
      if (graphicsData && graphicsData.length > 0) {
        let currentStopIndex = 0;
        let lat = graphicsData[0].latitude - 0.005;
        let lng = graphicsData[0].longitude - 0.005;

        const movementInterval = 500;
        const moveStep = 0.0001;

        const deg2rad = (deg) => deg * (Math.PI / 180);
        const getDistance = (lat1, lon1, lat2, lon2) => {
          const R = 6371; // Radius of the earth in km
          const dLat = deg2rad(lat2 - lat1);
          const dLon = deg2rad(lon2 - lon1);
          const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) *
              Math.cos(deg2rad(lat2)) *
              Math.sin(dLon / 2) *
              Math.sin(dLon / 2);
          const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
          return R * c; // Distance in km
        };

        const moveTowards = (
          currentLat,
          currentLng,
          targetLat,
          targetLng,
          step
        ) => {
          const dLat = targetLat - currentLat;
          const dLng = targetLng - currentLng;
          const distance = Math.sqrt(dLat * dLat + dLng * dLng);
          const ratio = step / distance;
          return {
            newLat: currentLat + dLat * ratio,
            newLng: currentLng + dLng * ratio,
          };
        };

        const intervalId = setInterval(() => {
          if (currentStopIndex >= graphicsData.length) {
            clearInterval(intervalId);
            return;
          }

          const nextStop = graphicsData[currentStopIndex];
          const distance = getDistance(
            lat,
            lng,
            nextStop.latitude,
            nextStop.longitude
          );

          if (distance < 0.015) {
            // Close enough to the destination
            currentStopIndex++;
            if (currentStopIndex >= graphicsData.length) {
              console.log("clear interval");
              clearInterval(intervalId);
              return;
            }
          }

          const { newLat, newLng } = moveTowards(
            lat,
            lng,
            nextStop.latitude,
            nextStop.longitude,
            moveStep
          );
          lat = newLat;
          lng = newLng;

          setUserPosition({ latitude: lat, longitude: lng });
          positionChange(oComponentModel, { latitude: lat, longitude: lng });
        }, movementInterval);

        return () => clearInterval(intervalId);
      }
    }
  }, [graphicsData, oComponentModel]);

  // useEffect to start and stop simulation
  useEffect(() => {
    const startSimulation = () => {
      if (currentStep < graphicsData.length) {
        updateSimulationStep(currentStep);
        simulationTimeoutRef.current = setTimeout(() => {}, 1000);
      }
    };

    const stopSimulation = () => {
      clearTimeout(simulationTimeoutRef.current);
      simulationTimeoutRef.current = null;
    };

    if (isSimulating || isDrag) {
      startSimulation();
    } else {
      stopSimulation();
    }

    return () => {
      stopSimulation();
    };
  }, [isSimulating, currentStep]);

  // useEffect to render slider
  useEffect(() => {
    if (bSimulation && graphicsData && graphicsData.length > 0) {
      let customLabels = graphicsData.map((data) => formatTime(data.time));
      customLabels.push(customLabels[customLabels.length - 1]);

      let sliderDiv = document.getElementById("sliderDiv");
      if (!sliderDiv) {
        const sliderHeadDiv = document.getElementById("slider_div_head");
        if (sliderHeadDiv) {
          sliderDiv = document.createElement("div");
          sliderDiv.id = "sliderDiv";
          sliderDiv.style.width = "80%";
          sliderHeadDiv.appendChild(sliderDiv);
          sliderDiv.onclick = handlePlay;
        }
      }

      const slider = new Slider({
        container: sliderDiv,
        min: 0,
        max: graphicsData.length,
        values: [0],
        snapOnClickEnabled: false,
        visibleElements: {
          labels: true,
          rangeLabels: true,
        },
        labelFormatFunction: (value) => {
          return customLabels[Math.round(value)];
        },
      });

      var lastLoggedValue = currentStep;
      slider.on("thumb-drag", (event) => {
        if (!isDrag) {
          setIsDrag(true);
        }
        const currentValue = Math.round(event.value);
        if (currentValue !== lastLoggedValue) {
          lastLoggedValue = currentValue;
          setCurrentStep(currentValue);
        }
      });

      setSlider(slider);
      const simulateButton = document.getElementById("simulateButton");
      simulateButton.style.display = "block";

      return () => {
        if (slider) {
          slider.destroy();
        }
        graphicsLayerRef.current.removeAll();
        simulateButton.style.display = "none";
        clearTimeout(animationFrameId);
      };
    }
  }, [graphicsLayerRef, graphicsData]);

  // slider play button logic
  const handlePlay = () => {
    setIsSimulating(!isSimulating);
    if (!isSimulating) {
      const animateSlider = () => {
        if (slider) {
          const currentValue = Math.round(slider.values[0]);
          const nextValue =
            currentValue < slider.max ? currentValue + 1 : slider.min;
          setCurrentStep(nextValue);
          slider.values = [nextValue];
        }
        const id = setTimeout(animateSlider, 1000);
        setAnimationFrameId(id);
      };

      animateSlider();
    } else {
      clearTimeout(animationFrameId);
    }
  };

  // simulation data update
  const updateSimulationStep = (step) => {
    if (graphicsData[step]) {
      const { latitude, longitude, label } = graphicsData[step];
      const point = new Point({ longitude, latitude });

      graphicsLayerRef.current.removeAll();

      const webStyleSymbol = new WebStyleSymbol({
        name: "Point symbol_4",
        styleUrl:
          "https://cdn.arcgis.com/sharing/rest/content/items/0d9f00ffc3af4d128ac5771e3dbce440/data",
      });

      const simulationMarker = new Graphic({
        geometry: point,
        symbol: webStyleSymbol,
      });

      const simulationText = new Graphic({
        geometry: point,
        symbol: new TextSymbol({
          text: label,
          color: "blue",
          haloColor: "white",
          haloSize: 1,
          xoffset: 0,
          yoffset: 10,
          font: { size: 12, family: "sans-serif", weight: "bold" },
        }),
      });

      graphicsLayerRef.current.add(simulationMarker);
      graphicsLayerRef.current.add(simulationText);
    }
  };

  // stop marking and stop rendering with live location
  useEffect(() => {
    if (graphicsLayerRef.current && graphicsData && graphicsData.length > 0) {
      graphicsLayerRef.current.removeAll();
      // Add graphics to the layer
      var graphics;
      if (!bSimulation) {
        graphics = graphicsData.map((data) => {
          const { latitude, longitude, color } = data;
          const point = new Point({
            longitude,
            latitude,
          });
          const webStyleSymbol = new WebStyleSymbol({
            name: markPostiton ? "Bus Stops" : "Point symbol_4",
            styleUrl: markPostiton
              ? "https://cdn.arcgis.com/sharing/rest/content/items/b117b6e14d7b429a9ea8c58a5cb6abad/data"
              : "https://cdn.arcgis.com/sharing/rest/content/items/0d9f00ffc3af4d128ac5771e3dbce440/data",
          });
          return new Graphic({
            geometry: point,
            symbol: webStyleSymbol,
          });
        });

        const textGraphics = graphicsData.map((data) => {
          const { latitude, longitude, label } = data;
          const point = new Point({
            longitude,
            latitude,
          });

          return new Graphic({
            geometry: point,
            symbol: new TextSymbol({
              text: label,
              color: "blue",
              haloColor: "white",
              haloSize: 1,
              xoffset: 0,
              yoffset: 10,
              font: { size: 12, family: "sans-serif", weight: "bold" },
            }),
          });
        });

        graphics.forEach((graphic) => graphicsLayerRef.current.add(graphic));
        textGraphics.forEach((graphic) =>
          graphicsLayerRef.current.add(graphic)
        );
      }

      // Calculate the extent
      if (graphicsData && graphicsData.length === 1 && livewithStops) {
        graphicsData.forEach(function (oData) {
          const { latitude, longitude } = oData;
          if (oData.stops) {
            oData.stops.forEach((oPoint) => {
              const webStyleSymbol = new WebStyleSymbol({
                name: "Bus Stops",
                styleUrl:
                  "https://cdn.arcgis.com/sharing/rest/content/items/b117b6e14d7b429a9ea8c58a5cb6abad/data",
              });
              const targetMarker = new Graphic({
                geometry: {
                  type: "point",
                  longitude: oPoint.longitude,
                  latitude: oPoint.latitude,
                },
                symbol: webStyleSymbol,
              });
              const textMarker = new Graphic({
                geometry: {
                  type: "point",
                  longitude: oPoint.longitude,
                  latitude: oPoint.latitude,
                },
                symbol: new TextSymbol({
                  text: oPoint.stopName,
                  color: "blue",
                  haloColor: "white",
                  haloSize: 1,
                  xoffset: 0,
                  yoffset: 10,
                  font: { size: 12, family: "sans-serif", weight: "bold" },
                }),
              });
              graphicsLayerRef.current.add(targetMarker);
              graphicsLayerRef.current.add(textMarker);
            });
          }
        });
      }

      const xCoordinates =
        graphics && graphics.length > 0
          ? graphics.map((g) => g.geometry.longitude)
          : graphicsData && graphicsData.length > 0
          ? graphicsData.map((g) => g.longitude)
          : [];

      const yCoordinates =
        graphics && graphics.length > 0
          ? graphics.map((g) => g.geometry.latitude)
          : graphicsData && graphicsData.length > 0
          ? graphicsData.map((g) => g.latitude)
          : [];

      // Ensure there are coordinates to calculate extent
      if (xCoordinates.length && yCoordinates.length) {
        const xmin = Math.min(...xCoordinates);
        const ymin = Math.min(...yCoordinates);
        const xmax = Math.max(...xCoordinates);
        const ymax = Math.max(...yCoordinates);

        // Add a margin to the extent for better visualization
        const extent = new Extent({
          xmin: xmin,
          ymin: ymin,
          xmax: xmax,
          ymax: ymax,
          spatialReference: 4326,
        });

        if (!initialViewSet) {
          mapViewRef.current
            .goTo({
              target: extent,
              zoom: 10, // Set the desired zoom level
            })
            .then(() => setInitialViewSet(true))
            .catch((err) => console.error(err));
        }
      }
    }
  }, [graphicsData, bRender, userPosition]);

  useEffect(() => {
    if (userLayerRef.current && userPosition) {
      userLayerRef.current.removeAll();

      const { latitude, longitude } = userPosition;
      const point = new Point({
        longitude,
        latitude,
      });
      const userGraphic = new Graphic({
        geometry: point,
        symbol: new SimpleMarkerSymbol({
          color: "orange",
          size: "12px",
          outline: {
            color: [255, 255, 255],
            width: 2,
          },
        }),
      });

      userLayerRef.current.add(userGraphic);

      mapViewRef.current
        .goTo({
          target: point,
          zoom: 15,
        })
        .catch((err) => console.error(err));
    }
  }, [userPosition]);

  return (
    <>
      <div id="topbar" style={{ display: "none" }}>
        <button
          class="action-button esri-icon-measure-line"
          id="distanceButton"
          type="button"
          title="Measure distance between two or more points"
        ></button>
      </div>
      {bSimulation && (
        <div
          id="slider_div_head"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            id="simulateButton"
            onClick={handlePlay}
            style={{ marginRight: "10px", display: "none",cursor:"pointer" }}
          >
            {isSimulating ? (
              <FontAwesomeIcon icon={faStop} size="xl" color="red" />
            ) : (
              <FontAwesomeIcon icon={faCirclePlay} size="xl" color="blue" />
            )}
          </div>
          <div id="sliderDiv" style={{ width: "80%" }} />
        </div>
      )}
      <div id="mapViewDiv" style={{ height: "100vh", width: "100%" }}></div>
    </>
  );
};

export default MapViewComponent;

//  simulation
// if (graphicsData && graphicsData.length > 0) {
//   graphicsData.forEach((oPoint) => {
//     oPoint.radius = 200;
//   });
//   console.log("target", graphicsData);
//   // const targetPoints = [
//   //   { id: 1, latitude: 17.4326, longitude: 78.4071, radius: 200 },
//   //   { id: 2, latitude: 17.4169, longitude: 78.4034, radius: 500 },
//   //   // Add more points as needed
//   // ];
//   const circles = graphicsData.map((point) => {
//     const circle = new Circle({
//       center: { latitude: point.latitude, longitude: point.longitude },
//       radius: point.radius,
//     });
//     const circleGraphic = new Graphic({
//       geometry: circle,
//       symbol: {
//         type: "simple-fill",
//         color: [51, 51, 204, 0.2],
//         outline: {
//           color: [51, 51, 204, 0.5],
//           width: 2,
//         },
//       },
//     });
//     userLayerRef.current.add(circleGraphic);

//     const targetMarker = new Graphic({
//       geometry: {
//         type: "point",
//         longitude: point.longitude,
//         latitude: point.latitude,
//       },
//       symbol: {
//         type: "simple-marker",
//         color: "red",
//         size: "12px",
//       },
//     });
//     userLayerRef.current.add(targetMarker);

//     return { circle, reached: false };
//   });

//   //   // Check user position against target points' radius
//   circles.forEach((circleObj) => {
//     const { circle } = circleObj;
//     if (geometryEngine.within(point, circle)) {
//       if (!circleObj.reached) {
//         circleObj.reached = true;
//         const currentTime = new Date();
//         console.log(
//           `the user postion of latitude is ${point.latitude} and longitude is ${point.longitude}`
//         );
//         // console.log(`User reached point ${circleObj} at: ${currentTime}`);
//         // Perform any additional logic here
//       }
//     } else {
//       circleObj.reached = false;
//     }
//   });
// }
