export const FetchInputs = (
  aProperties,
  oParams,
  aCurrentPage,
  oLogicDatas
) => {
  if(oLogicDatas){
    if (!oLogicDatas.oMapPosition || Object.keys(oLogicDatas.oMapPosition).length < 0) {
      oLogicDatas.aCurrentPage.forEach(function (oComponent) {
        if (oComponent.Component === "G_Gantt" && oComponent.Data) {
          oComponent.Data = [];
        }
      });
      oLogicDatas.resetUI([...oLogicDatas.aUIData]);
    }
  }
  aProperties.forEach(function (oProperties) {
    if (oProperties.InputType === 'inputComponentsCheckBox') {
      oProperties.value.forEach(function (oInputField) {
        aCurrentPage.forEach(function (oComponent) {
          if (oComponent.Component === 'Table') {
            var sValue = oComponent.properties.find((oProperty) => {
              return (
                oProperty.id === 'idInputTableName' &&
                oProperty.value === oInputField
              );
            });
            if (sValue) {
              var aValues = oComponent.value.rows;
              var aType = oComponent.properties.find((oProperty) => {
                return oProperty.id === 'idColumnConfig';
              }).value;
              var values = formatTableData(aValues, aType);
              oParams.push({ field: oInputField, value: values });
            }
          } else if (oComponent.Component === "MapLocation") {
            if (oLogicDatas.oMapPosition && oLogicDatas.oMapPosition[oInputField]) {
              oParams.push({
                field: oInputField,
                value: oLogicDatas.oMapPosition[oInputField],
              });
            }
          } else if(oComponent.Component === "Image") {
            var sData = oComponent.properties.find((oProperty) => {
              return (
                oProperty.id === 'idImageUploaderName' &&
                oProperty.value === oInputField
              );
            });
            if (sData) {
              if (oComponent.Files && oComponent.Files.length > 0) {
                oParams.push({
                  field: oInputField,
                  value: oComponent.binary,
                });
              }
            }
          } else if(oComponent.Component === "CSVFile") {
            sData = oComponent.properties.find((oProperty) => {
              return (
                oProperty.id === 'idCSVUploaderName' &&
                oProperty.value === oInputField
              );
            });
            if (sData) {
              if (oComponent.csvdata) {
                oParams.push({
                  field: oInputField,
                  value: oComponent.csvdata,
                });
              }
            }
          } else {
            sData = oComponent.properties.find((oProperty) => {
              return (
                oProperty.id === 'idInputFieldName' &&
                oProperty.value === oInputField
              );
            });
            if (sData) {
              var sType = oComponent.properties.find((oProperty) => {
                return oProperty.id === 'idInputTypeInput';
              });
              if (sType) {
                switch (sType.value) {
                  case 'Number':
                    oParams.push({
                      field: oInputField,
                      value: +oComponent.UserValue,
                    });
                    break;
                  case "file":
                    if (oComponent.Files && oComponent.Files.length > 0) {
                      oParams.push({
                        field: oInputField,
                        value: oComponent.binary,
                      });
                    }
                    break;
                  default:
                    oParams.push({
                      field: oInputField,
                      value: oComponent.UserValue,
                    });
                    break;
                }
              } else {
                oParams.push({
                  field: oInputField,
                  value: oComponent.UserValue,
                });
              }
            }
          }
        });
      });
    }
  });
};

/**
 * Get Data Type of Fetch user Table Inputs
 * @param {array} aValues
 * @param {array} aType
 */
function formatDataType(aValues, aType) {
  aValues.forEach(function (eachValue) {
    aType.forEach(function (eachType) {
      if (eachValue[eachType.fieldName]) {
        var type = eachType.dataType;
        var value;
        switch (type) {
          case 'Number':
            value = +eachValue[eachType.fieldName];
            eachValue[eachType.fieldName] = value;
            break;
          case 'Date':
            value = new Date(eachValue[eachType.fieldName]);
            eachValue[eachType.fieldName] = value;
            break;
          default:
            break;
        }
      }
    });
  });
  return aValues;
}
/**
 * Removing UnNecessary Row data in Table
 * @param {array} aValues
 * @param {array} aType
 */
function formatTableData(aValues, aType) {
  var aTableData = aValues;
  // aValues.forEach(function (oRowData) {
  //   if (aType.every(eachType => {
  //     const value = oRowData[eachType.fieldName];
  //     return value !== undefined && value !== null && value !== '';
  //   })) {
  //     aTableData.push(oRowData);
  //   }
  // });
  var values = formatDataType(aTableData, aType);
  return values;
}
