//Server
import Server from "../../../../serverDetails/server";

//logics
//MapData
import { MapTableData } from "./mapDataLogics/MapTableData";
import { MapInputFields } from "./mapDataLogics/MapTableData";
import { MapValueHelpData } from "./mapDataLogics/MapValueHelpData";
import { MapSingleData } from "./mapDataLogics/MapSingleData";
import { MapLocationData } from "./mapDataLogics/MapLocationData";
import { SplitColumnData } from "./mapDataLogics/SplitColumnsData";
import { MapBarChartData } from "./mapDataLogics/charts/MapBarChartData";
import { MapComponentVisibility } from "./mapDataLogics/MapComponentVisibility";
import { MapGanttChart } from "./mapDataLogics/charts/MapGanttChart";
import { MapG_GanttChart } from "./mapDataLogics/charts/MapG_GanttChart";

//Database Logics
import { FetchDB } from "./databaseLogic/FetchDB";
import { InsertDB } from "./databaseLogic/InsertDB";
import { DeleteDB } from "./databaseLogic/DeleteDB";
import { DeepFetchDB } from "./databaseLogic/DeepFetchDB";
import { DynamicApi } from "./databaseLogic/DynamicApi";

//Fetch Logics
import { FetchInputs } from "./fetchLogics/FetchInputs";
import { FetchParameter } from "./fetchLogics/FetchParameter";
import { TableSelectionFields } from "./tableSelection/TableSelectionFields";

//Arithmetic Operations
import { CalculateArithematicOperations } from "./arithmeticCalculator/CalculateArithematicOperations";
import { CalculateTableArithematicOperations } from "./arithmeticCalculator/CalculateTableArithematicOperations";

//Download
import { FormDownload } from "./Download/FormDownload";
import { DownloadCSV } from "./Download/CsvDownload";

//Code Editor Logics
import { CodeExecutor } from "./codeLogic/CodeExecutor";

//Send Email Logic
import { handleSignUpAndSendEmail } from "./sendEmail/SendEmail";

var bcontinueLoop = true;
/**
 * Execute Logic flow
 * @param {Object} aLogicDatas
 */
export async function executeLogicFlow(oLogicDatas) {
  var oParams = [];
  var aPromises = [];
  var oNextTarget = [];
  bcontinueLoop = true;

  for (var i = 0; i < oLogicDatas.aFlow.length; i++) {
    for (var j = 0; j < oLogicDatas.aFlow[i].target.length; j++) {
      if (bcontinueLoop) {
        oNextTarget = oLogicDatas.aFlow.find(function (oFlow) {
          return oFlow.id === oLogicDatas.aFlow[i].target[j].id;
        });

        if (
          oLogicDatas.aFlow[i].target[j].target === "idParameter" &&
          Object.keys(oLogicDatas.oQueryParameters).length === 0
        ) {
          if (
            oLogicDatas.aFlow[i].target.length > 1
          ) {
            var oTarget = oLogicDatas.aFlow[i].target[j]
            var aIDsToRemove = [oTarget.id];
              for (let i = 0; i < aIDsToRemove.length; i++) {
                var sTargetID = aIDsToRemove[i];
                var aTargets = oLogicDatas.aFlow.find(
                  (oLogicComponent) => oLogicComponent.id === sTargetID
                ).target;
                aTargets.forEach((oEachTarget) =>
                  aIDsToRemove.push(oEachTarget.id)
                );
              }
              const aFilteredArray = oLogicDatas.aFlow.filter(
                (obj) => !aIDsToRemove.includes(obj.id)
              );
              oLogicDatas.aFlow = aFilteredArray;
            formattedUIComponentsUserData(oLogicDatas);
            await executeTarget(
              oLogicDatas.aFlow[i].target[j],
              oLogicDatas,
              oParams,
              aPromises,
              oNextTarget
            );
          }
           else {
            formattedUIComponentsUserData(oLogicDatas);
            bcontinueLoop = false;
            break;
          }
        } else {
          await executeTarget(
            oLogicDatas.aFlow[i].target[j],
            oLogicDatas,
            oParams,
            aPromises,
            oNextTarget
          );
        }
      } else {
        break;
      }
    }
  }
}

/**
 * Execute target node
 * @param {object} oTarget | Target node
 * @param {string} sUniqueID | Unique component id
 * @param {object} oParams | parameters
 * @param {array} aPreviewProps | Preview props
 * @param {string} USERID
 * @param {array} aPromises
 * @param {function} fnCallback
 * @returns
 */
async function executeTarget(
  oTarget,
  oLogicDatas,
  oParams,
  aPromises,
  oNextTarget
) {
  switch (oTarget.target) {
    case "idMessage":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then((results) => {
          executeMeassage(
            oTarget.properties,
            oParams,
            oLogicDatas.aPreviewProps,
            results[0].value
          );
        });
      } else {
        executeMeassage(oTarget.properties, oParams, oLogicDatas.aPreviewProps);
      }
      break;
    case "idFetchInputs":
      FetchInputs(oTarget.properties, oParams, oLogicDatas.aCurrentPage, oLogicDatas);
      break;
    case "idInsertDB":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then(() => {
          InsertDB(oTarget.properties, oParams, oLogicDatas, aPromises);
        });
      } else {
        InsertDB(oTarget.properties, oParams, oLogicDatas, aPromises);
      }
      break;
    case "idFetchDB":
      if (aPromises.length > 2) {
        Promise.allSettled(aPromises).then(() => {
          FetchDB(oTarget.properties, oLogicDatas, oParams, aPromises, oNextTarget);
        });
      } else {
        FetchDB(oTarget.properties, oLogicDatas, oParams, aPromises, oNextTarget);
      }
      break;
    case "idDeepFetchDB":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then(() => {
          DeepFetchDB(oTarget.properties, oLogicDatas, oParams, aPromises, oNextTarget);
        });
      } else {
        DeepFetchDB(oTarget.properties, oLogicDatas, oParams, aPromises, oNextTarget);
      }
      break;
    case "idMapTableData":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then(() => {
          MapTableData(oTarget.properties, oLogicDatas, oParams);
        });
      } else {
        MapTableData(oTarget.properties, oLogicDatas, oParams);
      }
      break;
    case "idFetchDBSplitColumns":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then(() => {
          SplitColumnData(oTarget.properties, oParams, oLogicDatas);
        });
      } else {
        SplitColumnData(oTarget.properties, oParams, oLogicDatas);
      }
      break;
    case "idParameter":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then(() => {
          FetchParameter(
            oTarget.properties,
            oParams,
            oLogicDatas.oQueryParameters,
            oLogicDatas
          );
        });
      } else {
        FetchParameter(
          oTarget.properties,
          oParams,
          oLogicDatas.oQueryParameters,
          oLogicDatas
        );
      }
      break;
    case "idMapSingleField":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then(() => {
          MapSingleData(
            oLogicDatas.sUniqueID,
            oParams,
            oLogicDatas.aUIData,
            oLogicDatas.resetUI
          );
        });
      } else {
        MapSingleData(
          oLogicDatas.sUniqueID,
          oParams,
          oLogicDatas.aUIData,
          oLogicDatas.resetUI
        );
      }
      break;
    case "idNavigation":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then((results) => {
          NavigateToPage(
            oTarget.properties,
            oLogicDatas,
            oLogicDatas.aParameters,
            results
          );
        });
      } else {
        NavigateToPage(
          oTarget.properties,
          oLogicDatas,
          oLogicDatas.aParameters
        );
      }
      break;
    case "idMapInputComponents":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then((results) => {
          MapInputFields(oTarget.properties, oParams, oLogicDatas, results);
        });
      } else {
        MapInputFields(oTarget.properties, oParams, oLogicDatas);
      }
      break;
    case "idMRPCalculator":
      getMrpCalculatorData(
        oTarget.properties,
        oParams,
        aPromises,
        oLogicDatas.USERID
      );
      break;
    case "idValueHelpData":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then((results) => {
          MapValueHelpData(
            oTarget.properties,
            oLogicDatas.sUniqueID,
            oLogicDatas.aUIData,
            oLogicDatas.resetUI,
            oParams,
            oLogicDatas
          );
        });
      } else {
        MapValueHelpData(
          oTarget.properties,
          oLogicDatas.sUniqueID,
          oLogicDatas.aUIData,
          oLogicDatas.resetUI,
          oParams,
          oLogicDatas
        );
      }
      break;
    case "idArithematicCalculator":
      CalculateArithematicOperations(oTarget.properties, oParams);
      break;
    case "idTableArithematicCalculator":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then(() => {
          CalculateTableArithematicOperations(
            oTarget.properties,
            oParams,
            oLogicDatas.aUIData
          );
        });
      } else {
        CalculateTableArithematicOperations(
          oTarget.properties,
          oParams,
          oLogicDatas.aUIData
        );
      }
      break;
    case "idFormDownload":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then((result) => {
          FormPassParameter(
            oTarget,
            oTarget.properties,
            oLogicDatas,
            result,
            oParams
          );
        });
      } else {
        FormPassParameter(
          oTarget,
          oTarget.properties,
          oLogicDatas,
          [],
          oParams
        );
      }
      break;
    case "idTableSelection":
      TableSelectionFields(oTarget.properties, oParams, oLogicDatas.aUIData);
      break;
    case "idDeleteDB":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then(() => {
          DeleteDB(
            oTarget.properties,
            oParams,
            aPromises,
            stopLoop,
            oLogicDatas
          );
        });
      } else {
        DeleteDB(oTarget.properties, oParams, aPromises, stopLoop, oLogicDatas);
      }
      break;
    case "idMapBarChart":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then(() => {
          MapBarChartData(oTarget.properties, oParams, oLogicDatas);
        });
      } else {
        MapBarChartData(oTarget.properties, oParams, oLogicDatas);
      }
      break;
    case "idComponentVisibility":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then(() => {
          MapComponentVisibility(oTarget.properties, oParams, oLogicDatas);
        });
      } else {
        MapComponentVisibility(oTarget.properties, oParams, oLogicDatas);
      }
      break;
    case "idMapGanttChart":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then(() => {
          MapGanttChart(oTarget.properties, oParams, oLogicDatas);
        });
      } else {
        MapGanttChart(oTarget.properties, oParams, oLogicDatas);
      }
      break;
    case "idMapGoogleGanttChart":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then(() => {
          MapG_GanttChart(oTarget.properties, oParams, oLogicDatas);
        });
      } else {
        MapG_GanttChart(oTarget.properties, oParams, oLogicDatas);
      }
      break;
    case "idExecuteConfirmation":
      await ExecuteConfirmation(oTarget.properties, oLogicDatas, oTarget);
      break;
    case "idCodeEditor":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then(() => {
          CodeExecutor(oTarget.properties, oParams, oLogicDatas);
        });
      } else {
        CodeExecutor(oTarget.properties, oParams, oLogicDatas);
      }
      break;
    case "idDynamicRowLocking":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then(() => {
          mapDynamicRowLocking(oTarget.properties, oParams, oLogicDatas);
        });
      } else {
        mapDynamicRowLocking(oTarget.properties, oParams, oLogicDatas);
      }
      break;
    case "idSendEmail":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then(() => {
          handleSignUpAndSendEmail(oTarget.properties, oParams, aPromises,oLogicDatas,stopLoop);
        });
      } else {
        handleSignUpAndSendEmail(oTarget.properties, oParams, aPromises,oLogicDatas,stopLoop);
      }
      break;
    case "idFunctionCall":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then(() => {
          handleFunctionCall(oTarget.properties, oParams, oLogicDatas);
        });
      } else {
        handleFunctionCall(oTarget.properties, oParams, oLogicDatas);
      }
      break;
    case "idClearData":
      ClearData(oLogicDatas);
      break;
    case "idApiCall":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then(() => {
          DynamicApi(oTarget.properties, oParams, aPromises, oLogicDatas);
        });
      } else {
        DynamicApi(oTarget.properties, oParams, aPromises, oLogicDatas);
      }
      break;
    case "idMessageList":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then(() => {
          handleMessageList(oParams, oLogicDatas);
        });
      } else {
        handleMessageList(oParams, oLogicDatas);
      }
      break;
    case "idMapView":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then(() => {
          MapLocationData(oTarget.properties, oParams, oLogicDatas);
        });
      } else {
        MapLocationData(oTarget.properties, oParams, oLogicDatas);
      }
      break;
    case "idMapDropDownField":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then(() => {
          MapDropDownFields(oTarget.properties, oParams, oLogicDatas);
        });
      } else {
        MapDropDownFields(oTarget.properties, oParams, oLogicDatas);
      }
      break;
    case "idDownloadCSV":
      if (aPromises.length > 0) {
        Promise.allSettled(aPromises).then(() => {
          DownloadCSV(oTarget.properties, oParams, oLogicDatas);
        });
      } else {
        DownloadCSV(oTarget.properties, oParams, oLogicDatas);
      }
      break;
      case "idMapCSV":
        if (aPromises.length > 0) {
          Promise.allSettled(aPromises).then(() => {
            handleMapCsvToTable(oTarget.properties, oParams, oLogicDatas);
          });
        } else {
          handleMapCsvToTable(oTarget.properties, oParams, oLogicDatas);
        }
        break;
    default:
      return;
  }
}

/**
 * Execute Message logic node
 * @param {object} oProperties | properties of the node
 * @param {object} oParams | parameters
 * @param {array} aPreviewProps | preview nodes
 * @param {function} fnCallback
 */
function executeMeassage(oProperties, oParams, aPreviewProps, results) {
  if (results) {
    let startChar = "{";
    let endChar = "}";
    let startIndex = oProperties[0].value.indexOf(startChar);
    let endIndex = oProperties[0].value.indexOf(endChar);
    let substring = oProperties[0].value.substring(startIndex + 1, endIndex);
    let iDot = substring.indexOf(".");
    let aObject = [];
    var smessage = oProperties[0].value.replace(
      "{" + substring + "}",
      results[substring]
    );
    if (iDot > -1) {
      var sValue = "";
      aObject = substring.split(".");
      sValue = results;
      //var sEndMessage = results.message.split(" ")[1];
      for (var i = 0; i < aObject.length; i++) {
        sValue = sValue[aObject[i]];
      }
      //sValue = sValue + " " + sEndMessage;
      smessage = oProperties[0].value.replace("{" + substring + "}", sValue);
    }
    aPreviewProps["message"].setOpenMessage(true);
    aPreviewProps["message"].setMessage(smessage);
  } else {
    aPreviewProps["message"].setOpenMessage(true);
    aPreviewProps["message"].setMessage(oProperties[0].value);
  }
}

/**
 * 
 * @param {*} oParams 
 * @param {*} oLogicDatas 
 */
function handleMessageList(oParams,oLogicDatas){
  var aMessage = []
  if(oParams.Message){
    var data = {
      fieldName:"",
      message:oParams.Message,
      messageType:"error"
    }
    aMessage.push(data);
    oLogicDatas.validationmessage(aMessage);
    oLogicDatas.validationdialog(true);
  }
}

/**
 * handle the NavigatePage
 * @param {array} aProperties
 * @param {object} oLogicDatas
 * @param {array} aParameters
 * @param {array} results
 */
function NavigateToPage(aProperties, oLogicDatas, aParameters, results) {
  aParameters = [];
  var oAppNavigationCheck = aProperties.find(function (oProperty) {
    return oProperty.id === "idAppNavigationCheckBox";
  });
  var aInputs = aProperties.find(function (oProperty) {
    return oProperty.id === "idNavigationParameters";
  }).value;
  if (aInputs) {
    aInputs.forEach((oInput) => {
      oLogicDatas.aCurrentPage.forEach((oUIData) => {
        if (oUIData.Component === "Input" || oUIData.Component === "DropDown") {
          if (oUIData.uniqueId === oInput.parameterValue) {
            var sUserValue;
            if (oUIData.UserValue) {
              sUserValue = oUIData.UserValue;
            } else {
              sUserValue = document.getElementById(oUIData.uniqueId).value;
              if (!sUserValue && results.length > 0) {
                if (results[0].status === "fulfilled") {
                  sUserValue = results[0].value[oInput];
                }
              }
            }
            aParameters.push({
              field: oInput.parameterName,
              value: sUserValue,
            });
          }
        }
        if (oUIData.Component === "Table") {
          if (
            oUIData.uniqueId === oInput.parameterValue &&
            oInput.tableSelection
          ) {
            if (oInput.arraySelection) {
              const aSelectedData = [];
              oUIData.value.rows.forEach((row, index) => {
                if (oUIData.checkValues[index].all === true) {
                  aSelectedData.push(row);
                }
              });
              aParameters.push({
                field: oInput.parameterName,
                value: aSelectedData,
                state: true,
              });
            } else {
              let iIndex = oUIData.checkValues.findIndex(
                (oCheckRow) => oCheckRow.all === true
              );
              var oSelectedRowData =
                oUIData.value.rows[iIndex][oInput.mappingValue];
              aParameters.push({
                field: oInput.parameterName,
                value: oSelectedRowData,
              });
            }
          } else if (oUIData.uniqueId === oInput.parameterValue) {
            var iIndex = oUIData.index;
            var oSelectedRowData =
              oUIData.value.rows[iIndex][oInput.mappingValue];
            aParameters.push({
              field: oInput.parameterName,
              value: oSelectedRowData,
            });
          }
        }
        if (oUIData.Component === "G_Gantt") {
          if (oUIData.uniqueId === oInput.parameterValue) {
            var iIndex = oUIData.index;
            var oSelectedRowData = oUIData.AllData[iIndex][oInput.graphField];
            aParameters.push({
              field: oInput.parameterName,
              value: oSelectedRowData,
            });
          }
        }
        if (oUIData.Component === "BarChart" || oUIData.Component === "PieChart") {
          if (oUIData.uniqueId === oInput.parameterValue) {
            var selection = oUIData.barSelection;
            var oSelectedRowData = oUIData.AllData[selection.row][oInput.graphField];
            aParameters.push({
              field: oInput.parameterName,
              value: oSelectedRowData,
            });
          }
        }
      });
    });
  }

  if (oAppNavigationCheck !== undefined && oAppNavigationCheck.value) {
    var oAppNavigation = aProperties.find(function (oProperty) {
      return oProperty.id === "idAppNavigation";
    });
    var sAPPID = oAppNavigation.value.APPID;
    var sPageID = oAppNavigation.value.pageID;
  } else {
    var sPageID = aProperties.find(function (oProperty) {
      return oProperty.id === "idPageList";
    });
    if (sPageID) {
      sPageID = sPageID.value;
    }
  }
  oLogicDatas.fnNavigate(sPageID, aParameters, sAPPID);
}

/**
 * Request the MrPCalculator and Get Response
 * @param {array} aProperties
 * @param {object} oParams
 * @param {array} aPreviewProps
 * @param {arrau} aUIData
 * @param {string} USERID
 * @param {array} aPromises
 */
function getMrpCalculatorData(oProperties, oParams, aPromises, USERID) {
  var oProperty = oProperties.find(function (oProperty) {
    return oProperty.id === "idMRPRequiredFields";
  });
  var userRequest = oProperty.value;
  var materialNumber = oParams.find(function (oInputField) {
    return oInputField.field === userRequest.material;
  }).value;
  var oPostData = {
    PROJECTID: "STUDIO_" + USERID.substring(0, 4),
    SalesOrder: userRequest.SalesOrder,
    Inventory: userRequest.Inventory,
    MaterialMaster: userRequest.MaterialMaster,
    PlannedOrder: userRequest.PlannedOrder,
    PurchaseRequisition: userRequest.PurchaseRequisition,
    material: materialNumber,
    dateRange: {
      from: "2022-07-20T18:25:43-02:00",
      to: "9999-12-31T18:25:43-05:00",
    },
  };
  aPromises.push(
    Server.post("/mrpCalculator", oPostData, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    }).then(function (oResult) {
      oParams.TableData = oResult.data;
    })
  );
}

function addLoopComponents(datas,aLoopComponents,oParams,aMapDataValues){
  datas.forEach(function (oUIData) {
    if (oUIData.Component === "Loop") {
      var iLoopCount;
      var iTop = oUIData.top;
      const iHeight = oUIData.DivHeight;
      oUIData.properties.forEach(function (oProperty) {
        if (oProperty.id === "idLoopConfig") {
          var aComponentId = oProperty.value.map((prop) => prop.id);
          aComponentId.forEach((componentId) => {
            var iOriginalComponentIndex = datas.findIndex(
              (item) => item.uniqueId === componentId
            );
            if (iOriginalComponentIndex !== -1) {
              var oOriginalComponent = datas[iOriginalComponentIndex];
              aMapDataValues.forEach(function (oValues) {
                if(oValues.ParameterID === oOriginalComponent.uniqueId){
                  iLoopCount = oParams[oValues.MapValue].length;
                }
              });
              aLoopComponents.push(oOriginalComponent)
              var iComponentTop = oOriginalComponent.top;
              var iTopDifference = iComponentTop - iTop;
              for (var i = 1; i < iLoopCount; i++) {
                iTop = iTop + iHeight;
                var oCopiedComponent = JSON.parse(
                  JSON.stringify(oOriginalComponent)
                );
                oCopiedComponent.top = iTop + iTopDifference;
                oCopiedComponent.uniqueId =
                  `${oCopiedComponent.uniqueId}` + `${i}`;
                aLoopComponents.push(oCopiedComponent)
                datas.splice(
                  iOriginalComponentIndex + i,
                  0,
                  oCopiedComponent
                );
              }
            }
          });
        }
      });
    }
  });
}

function FormPassParameter(oTarget, aProperties, oLogicDatas, result, oParams) {
  const iFetchInputsIndex = oLogicDatas.aFlow.findIndex(
    (item) => item.source === oTarget.target
  );

  const sPreviousTarget = oLogicDatas.aFlow[iFetchInputsIndex - 1].source;
  var oData;

  if (sPreviousTarget === "idFetchInputs") {
    if (Array.isArray(oParams)) {
      var aFormattedData = {};
      oParams.forEach(function (param) {
        aFormattedData[param.field] = param.value;
      });
      if (Object.keys(aFormattedData).length > 0) {
        oData = aFormattedData;
      }
    }
  }

  const aMapDataValues = aProperties.find(
    (oProperty) => oProperty.id === "idFormMapData"
  ).value;
  const sPDF = aProperties.find(
    (oProperty) => oProperty.id === "idPDFName"
  )?.value;

  const regex = /\{([^}]+)\}/;
  var sHeaderData;
  var valueInsideBraces;
  if(sPDF){
    const match = sPDF.match(regex);
    if (match) {
      valueInsideBraces = match[1].trim();
    }
  }

  var aFormParameters = [];
  if (sPreviousTarget === "idFetchInputs") {
    if(valueInsideBraces){
      sHeaderData = oData[valueInsideBraces]
    }
    aMapDataValues.forEach(function (oValues) {
      var oFormattedData = {};
      oFormattedData.field = oValues.ParameterID;
      if(oValues.DataType === 'Date'){
        const datePart = oData[oValues.MapValue].split('T')[0];
        const [year, month, day] = datePart.split('-');
        const formattedDate = `${day}-${month}-${year}`;
        oFormattedData.value = formattedDate;
      }else{
        oFormattedData.value = oData[oValues.MapValue];
      }
      aFormParameters.push(oFormattedData);
    });
  } else {
    if(valueInsideBraces){
      sHeaderData = oParams.TableData[0][valueInsideBraces]
    }
    aMapDataValues.forEach(function (oValues) {
      if (!oValues.bLoop) {
        var oFormattedData = {};
        if (oValues.DataType === "Array") {
          oFormattedData.field = oValues.ParameterID;
          oFormattedData.value = oParams[oValues.MapValue];
        } else if (oValues.DataType === "Date") {
          oFormattedData.field = oValues.ParameterID;
          const datePart = oParams.TableData[0][oValues.MapValue].split('T')[0];
          const [year, month, day] = datePart.split('-');
          const formattedDate = `${day}-${month}-${year}`;
          oFormattedData.value = formattedDate;
        } else {
          oFormattedData.field = oValues.ParameterID;
          oFormattedData.value = oParams.TableData[0][oValues.MapValue];
        }
        aFormParameters.push(oFormattedData);
      }
    });
  }

  var oAllUIData = { ...oLogicDatas.allUIData };
  var formName = Object.keys(oAllUIData.forms).find(function (forms) {
    return oAllUIData.forms[forms].name === aProperties[0].value;
  });
  const aUIData = oAllUIData.forms[formName].UIComponents;
  var aLoopComponents = [];
  addLoopComponents(aUIData, aLoopComponents, oParams, aMapDataValues);
  oAllUIData.forms[formName].UIComponents = aUIData;

  var bJoinForm = aProperties.find(
    (oProperty) => oProperty.id === "idJoinForm"
  ).value;

  if (bJoinForm) {
    var sJoinForm = aProperties.find(
      (oProperty) => oProperty.id === "idJoinFormName"
    ).value;
    var joinformName = Object.keys(oAllUIData.forms).find(function (forms) {
      return oAllUIData.forms[forms].name === sJoinForm;
    });
    var aJoinUIData = oAllUIData.forms[joinformName].UIComponents;
    addLoopComponents(aJoinUIData, aLoopComponents, oParams, aMapDataValues);
    oAllUIData.forms[joinformName].UIComponents = aJoinUIData;
  }

  aMapDataValues.forEach(function (oValues) {
    if (oValues.bLoop && oValues.DataType === "Array") {
      var iTemp = 0;
      aLoopComponents.forEach(function (oComponent, index) {
        if (oComponent.uniqueId.includes(oValues.ParameterID)) {
          var oFormattedData = {};
          oFormattedData.field = oComponent.uniqueId;
          oFormattedData.value =
            oParams[oValues.MapValue][iTemp][oValues.DefaultValue];
          aFormParameters.push(oFormattedData);
          iTemp++;
        }
      });
    }
  });
  FormDownload(aProperties, oAllUIData, aFormParameters, sHeaderData);
}

const stopLoop = () => {
  bcontinueLoop = false;
};


async function ExecuteConfirmation(oProperties, oLogicDatas, oTarget) {
  const sMessage = oProperties.find(
    (oProperty) => oProperty.id === "idConfirmationMessage"
  ).value;
  const aValues = oProperties.find(
    (oProperty) => oProperty.id === "idConfirmationInputs"
  ).value;
  const bEnableConfirm = oProperties.find(
    (oProperty) => oProperty.id === "idEnableOk"
  ).value;
  const bVisible = oProperties.find(
    (oProperty) => oProperty.id === "idEnableInvisible"
  ).value;

  async function performConfirmation() {
    const oConfirmationPromise = await oLogicDatas.confirmation(
      sMessage,
      bEnableConfirm
    );
    bcontinueLoop = oConfirmationPromise.success;
  }
  if (aValues.length > 0) {
    for (const oComponent of oLogicDatas.aUIData) {
      for (const oData of aValues) {
        if (oData.Field === oComponent.uniqueId) {
          const userValue = String(oComponent.UserValue).toLowerCase();
          const dataValue = String(oData.Value).toLowerCase();
          if (
            (oData.Condition === "Null" && userValue !== "") ||
            (oData.Condition === "Not Null" && userValue === "") ||
            (oData.Condition === "Equal to" && userValue !== dataValue) ||
            (oData.Condition === "NEqual to" && userValue === dataValue)
          ) {
            if (bVisible) {
              var aIDsToRemove = [oTarget.id];
              for (let i = 0; i < aIDsToRemove.length; i++) {
                var sTargetID = aIDsToRemove[i];
                var aTargets = oLogicDatas.aFlow.find(
                  (oLogicComponent) => oLogicComponent.id === sTargetID
                ).target;
                aTargets.forEach((oEachTarget) =>
                  aIDsToRemove.push(oEachTarget.id)
                );
              }
              const aFilteredArray = oLogicDatas.aFlow.filter(
                (obj) => !aIDsToRemove.includes(obj.id)
              );
              oLogicDatas.aFlow = aFilteredArray;
              break;
            } else {
              await performConfirmation();
            }
          }
        }
      }
    }
  } else {
    await performConfirmation();
  }
}

/**
 *  formatting UIComponents UserData
 * @param {object} oParams | parameters
 */
function formattedUIComponentsUserData(oLogicDatas) {
  oLogicDatas.aCurrentPage.forEach(function (oComponent) {
    if (oComponent.Component === "Input" && (oComponent.UserValue || oComponent.Files)) {
      oComponent.UserValue = "";
      if (oComponent.Files && oComponent.Files.length > 0) {
        oComponent.Files = [];
        oComponent.preview = [];
      }
      var aTempProperties = oComponent.properties;
      var oInputDefaultProperty = oComponent.inputDefault;
      if (oInputDefaultProperty) {
        aTempProperties.push(oInputDefaultProperty);
      }
      oComponent.properties = aTempProperties;
    } else if (oComponent.Component === "DropDown" && oComponent.UserValue) {
      oComponent.UserValue = "";
    } else if (oComponent.Component === "Table" && oComponent.value.rows) {
      oComponent.value.rows = [];
    }
  });
  oLogicDatas.resetUI([...oLogicDatas.aUIData]);
}

function FormatDynamicrowlocking(DynamicRowValue) {
  DynamicRowValue.forEach(function (oData){
    if(oData.condition && oData.datatype){
      if(oData.datatype === 'Date'){
        var currentDate = new Date();
        currentDate.setHours(0);
        currentDate.setMinutes(0);
        currentDate.setSeconds(0);
        currentDate.setMilliseconds(0);
        switch (oData.condition) {
          case 'eq':
            break;
          case 'ne':
            break;
          case 'lt':
            if(oData.conditionValue === "oneday"){
              currentDate.setDate(currentDate.getDate());
              oData.conditionValue = currentDate.toISOString().slice(0, 10);
            } else if(oData.conditionValue === "twoday"){
              currentDate.setDate(currentDate.getDate() - 1);
              oData.conditionValue = currentDate.toISOString().slice(0, 10);
            } else if(oData.conditionValue === "threeday"){
              currentDate.setDate(currentDate.getDate() - 2);
              oData.conditionValue = currentDate.toISOString().slice(0, 10);
            } else if(oData.conditionValue === "oneweek"){
              currentDate.setDate(currentDate.getDate() - 6);
              oData.conditionValue = currentDate.toISOString().slice(0, 10);
            } else if(oData.condition === "onemonth"){
              currentDate.setMonth(currentDate.getMonth() - 1);
              oData.conditionValue = currentDate.toISOString().slice(0, 10);
            }
            break;
          case 'gt':
            break;
          case 'lte':
            break;
          case 'gte':
            break;
          default:
            break;
        }
      }
    }
  })
  return DynamicRowValue;
}

/**
 *  mappingTableDynamicRowConditions
 * @param {array} aProperties | properties of the node
 * @param {object} oParams | parameters
 * @param {array} aUIData |
 */
function mapDynamicRowLocking(aProperties, oParams, oLogicDatas) {
  var DynamicRowValue = aProperties.find(
    (oProperty) => oProperty.id === "idMapDynamicRowLocking"
  ).value;
  DynamicRowValue = FormatDynamicrowlocking(DynamicRowValue)
  var targetComponet = oLogicDatas.aUIData.find(
    (oComponent) => oComponent.uniqueId === DynamicRowValue[0].tableID
  );
  targetComponet.RowLocking = DynamicRowValue;
}

function handleFunctionCall(aProperties,oParams,oLogicDatas){
  var sUniqueID = aProperties.find(
    oProperty => oProperty.id === "idFunctionComponent"
  )?.uniqueId;
  var sFunction = aProperties.find(
    oProperty => oProperty.id === "idFunctions"
  )?.value;
  oLogicDatas.fFunctionCall(sUniqueID,sFunction)
}

function ClearData(oLogicDatas) {
  oLogicDatas.aCurrentPage.forEach(function (oComponent) {
    if (oComponent.Component === "Input" && (oComponent.UserValue || oComponent.Files)) {
      oComponent.UserValue = "";
      if (oComponent.Files && oComponent.Files.length > 0) {
        oComponent.Files = [];
        oComponent.preview = [];
      }
      var aTempProperties = oComponent.properties;
      var oInputDefaultProperty = oComponent.inputDefault;
      if (oInputDefaultProperty) {
        aTempProperties.push(oInputDefaultProperty);
      }
      oComponent.properties = aTempProperties;
    } else if (oComponent.Component === "DropDown" && oComponent.UserValue) {
      oComponent.UserValue = "";
    } else if (oComponent.Component === "Table" && oComponent.value.rows) {
      oComponent.value.rows = [];
    } else if (oComponent.Component === "G_Gantt" && oComponent.Data) {
      oComponent.Data = [];
    } else if (oComponent.Component === "MapLocation" && oComponent.center) {
      oComponent.center = []
    }
  });
  oLogicDatas.resetUI([...oLogicDatas.aUIData]);
}

function formatDateField(value, dataType) {
  const dDate = new Date(value);
  switch (dataType) {
    case "Date": {
      return dDate.toISOString().split("T")[0];
    }
    case "datetime-local": {
      return dDate.toISOString().slice(0, 16);
    }
    default:
      return value;
  }
}

function handleMapCsvToTable(aProperties, oParams, oLogicDatas){
  var sDataField = aProperties.find(
    oProperty => oProperty.id === "idMapCsvToTable"
  ).value;
  var sTable = aProperties.find(
    oProperty => oProperty.id === "idCSVUploadTable"
  ).value;
  var aData = [];
  if(Array.isArray(oParams)){
    oParams.forEach(function (oData){
      if(oData.field === sDataField){
        aData = oData.value;
      }
    })
  }
  console.log(aProperties)
  console.log(oParams)
  var oComponent = oLogicDatas.aCurrentPage.find(
    function (oComponentModel) {
      return oComponentModel.uniqueId === sTable;
    }
  );
  oComponent.properties.forEach(function (oProperty) {
    if (oProperty.id === "idColumnConfig") {
      oProperty.value.forEach(function (oValue) {
        aData.forEach(async function (oDataValue) {
          const sFieldName = oValue.fieldName;
          if (oDataValue[sFieldName]) {
            oDataValue[sFieldName] = formatDateField(
              oDataValue[sFieldName],
              oValue.dataType
            );
          }
        });
      });
    }
  });
  oComponent.value = {
    rows: aData,
  };
  oLogicDatas.resetUI([...oLogicDatas.aUIData]);
}

const MapDropDownFields = async(aProperties, oParams, oLogicDatas) => {
  var oMapping = aProperties.find((oProperty) => {
    return oProperty.id === "idDropDownDialog";
  });
  var oData;
  if (Array.isArray(oParams.TableData) && oParams.TableData.length > 0) {
    oData = oParams.TableData[0];
  } else if (
    oParams.TableData &&
    typeof oParams.TableData === "object" &&
    Object.keys(oParams.TableData).length > 0
  ) {
    oData = oParams.TableData;
  } else if (oParams.mapSingle) {
    oData = oParams.mapSingle;
  } else if (Array.isArray(oParams)) {
    var aFormattedData = [];
    oParams.forEach(function (param) {
      aFormattedData[param.field] = param.value;
    });
    if (Object.keys(aFormattedData).length > 0) {
      oData = aFormattedData;
    }
  }

  if (oMapping && (oParams.mapSingle || oParams.TableData || oParams)) {
    for (var oProperty in oMapping.value) {
      var oComponent = oLogicDatas.aCurrentPage.find(
        function (oComponentModel) {
          return oComponentModel.uniqueId === oMapping.value[oProperty].id;
        }
      );
      var oTypeProperty = oComponent.properties.find(
        (oProperty) => oProperty.id === "idInputOptions"
      );
      if (
        (oComponent && oComponent.Component === "DropDown")
      ) {
        if (oMapping.value[oProperty].array) {
          var sArrayField = oMapping.value[oProperty].array;
          var sValueFields = oMapping.value[oProperty].value.split(".") ?? [];

          var sValue = oData[sArrayField][0];
          for (var i = 0; i < sValueFields.length; i++) {
            var propertyName = sValueFields[i];
            if (Array.isArray(sValue)) {
              sValue = sValue[0];
            }
            if (
              sValue &&
              Object.prototype.hasOwnProperty.call(sValue, propertyName)
            ) {
              sValue = sValue[propertyName];
            } else {
              sValue = "";
              break;
            }
          }
          oTypeProperty.value = sValue;
        } else {
          oTypeProperty.value = oData[oMapping.value[oProperty].value] ?? "";
        }
      }
    }
    oLogicDatas.resetUI([...oLogicDatas.aUIData])
  }
};