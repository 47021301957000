import React, { useState } from "react";
import Papa from "papaparse";

const UploadCSV = ({oComponentModel,aUIComponents,changeLogic}) => {
  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          oComponentModel.csvdata = result.data;
          changeLogic(event, oComponentModel, aUIComponents)
        },
        error: (error) => {
          console.error("Error parsing CSV:", error);
        },
      });
    }
  };

  return (
    <div>
      <input type="file" accept=".csv" onChange={handleFileUpload} />
    </div>
  );
};

export default UploadCSV;
